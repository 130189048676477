.ant-input {
  color: #212430 !important;
}

.ant-input-affix-wrapper {
  box-shadow: 0px 4px 4px rgba(174, 174, 174, 0.2);
}

.ant-input::placeholder {
  color: #828282 !important;
}

.ant-input:not(:focus) {
  color: #828282 !important;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
