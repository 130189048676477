@font-face {
  font-family: "ProximaNova";
  src: local("ProximaNova"),
    url("./assets/fonts/Mark\ Simonson\ -\ Proxima\ Nova\ Regular.otf")
      format("otf");
  font-weight: 400; /* Regular weight */
  font-style: normal;
}

@font-face {
  font-family: "ProximaNova";
  src: local("ProximaNova"),
    url("./assets/fonts/Mark\ Simonson\ -\ Proxima\ Nova\ Bold.otf")
      format("opentype");
  font-weight: 700;
}
